<template>
  <div id="reports-tabs">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Alunos" icon-pack="feather" icon="icon-users">
            <div class="tab-text">
              <students class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Financeiro" icon-pack="feather" icon="icon-dollar-sign">
            <div class="tab-text">
              <financial class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="CNH" icon-pack="feather" icon="icon-layout">
            <div class="tab-text">
              <cnh class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Protocolos" icon-pack="feather" icon="icon-file-text">
            <div class="tab-text">
              <protocols class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Agendas" icon-pack="feather" icon="icon-calendar">
            <div class="tab-text">
              <schedules class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Serviços" icon-pack="feather" icon="icon-check-square">
            <div class="tab-text">
              <services class="mt-4" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>

import Students   from './tabs/Students/Students.vue'
import Financial  from './tabs/Financial/Financial.vue'
import Cnh        from './tabs/Cnh/Cnh.vue'
import Protocols  from './tabs/Protocols/Protocols.vue'
import Schedules  from './tabs/Schedules/Schedules.vue'
import Services   from './tabs/Services/Services.vue'

export default {
  components: {
    Students,
    Financial,
    Cnh,
    Protocols,
    Schedules,
    Services
  },
  data () {
    return {
      activeTab: 0
    }
  }
}
</script>

<style lang="scss">
#reports-tabs {
  .vs-tabs--content {
    padding: 0 10px 23px !important;
  }
}
</style>
