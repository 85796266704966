var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("popup-pdf", {
        ref: "popupPdf",
        on: {
          show: function ($event) {
            return _vm.$emit("show")
          },
        },
      }),
      _c("select-employee", {
        attrs: { multiple: true },
        model: {
          value: _vm.employees,
          callback: function ($$v) {
            _vm.employees = $$v
          },
          expression: "employees",
        },
      }),
      _c("vs-divider"),
      _c(
        "div",
        { staticClass: "flex flex-row-reverse" },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.showPdf },
            },
            [_vm._v("Confirmar")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }