<template>
  <div id="student-documents">
    <exam-pass-rate-financial-popup v-model="examPassModel" :active.sync="activeExamPassRateFinancialPopup"
      :format="format" />
    <exam-pass-rate-popup v-model="examPassModel" :active.sync="activeExamPassRatePopup" :format="format" />
    <vs-popup class="popup80 noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <!-- Aula Prática -->
    <vs-popup class="popup80" :fullscreen="false" title="Relatório de Agendamentos de Aulas Práticas"
      :active.sync="popupPracticalSchedule">
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Aluno(s)</label>
          <div class="mt-0">
            <v-select class="vue_select_drop_size_150" v-model="practicalSchedule.students"
              @search="debouncedGetSearchStudent" multiple :clearable="false" :filterable="true"
              :reduce="option => option.value" :options="studentOptions" placeholder="Digite CPF ou nome do aluno..."
              :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Instrutor(es)</label>
          <v-select class="vue_select_drop_size_150" v-model="practicalSchedule.employees"
            :reduce="option => option.value" multiple :options="employees" placeholder="Selecione"
            :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Veículo(s)</label>
          <v-select class="vue_select_drop_size_150" v-model="practicalSchedule.vehicles"
            :reduce="option => option.value" multiple :options="vehicles" placeholder="Selecione"
            :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Tipo Veículo</label>
          <v-select class="vue_select_drop_size_90" v-model="practicalSchedule.type_vehicle"
            :reduce="option => option.value" :options="[
              { value: 'CAR', label: 'Carro' },
              { value: 'MOTORCYCLE', label: 'Moto' },
              { value: 'TRUCK', label: 'Caminhão' },
              { value: 'BUS', label: 'Ônibus' }
            ]" placeholder="TODOS" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Situação</label>
          <v-select class="vue_select_drop_size_90" v-model="practicalSchedule.status" :reduce="option => option.value"
            :options="[
              { value: 'Concluído', label: 'Concluído' },
              { value: 'Não Concluído', label: 'Não Concluído' },
              { value: 'ABSENCE', label: 'Ausência' },
            ]" placeholder="TODOS" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupPracticalSchedule = false">Cancelar</vs-button>
            <vs-button class="ml-4" @click="generateReport(1)">Gerar Documento</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- Aula Teórica -->
    <vs-popup class="popup80" :fullscreen="false" title="Relatório de Agendamentos de Aulas Teóricas"
      :active.sync="popupTheoreticalSchedule">
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Aluno(s)</label>
          <div class="mt-0">
            <v-select class="vue_select_drop_size_150" v-model="theoreticalSchedule.students"
              @search="debouncedGetSearchStudent" multiple :clearable="false" :filterable="true"
              :reduce="option => option.value" :options="studentOptions" placeholder="Digite CPF ou nome do aluno..."
              :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Instrutor(es)</label>
          <v-select class="vue_select_drop_size_150" v-model="theoreticalSchedule.employees"
            :reduce="option => option.value" multiple :options="employees" placeholder="Selecione"
            :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Turma(s)</label>
          <v-select v-model="theoreticalSchedule.turmas" :clearable="true" :filterable="true" multiple
            @search="debouncedGetSearchTurma" :reduce="option => option.value" :options="turmaOptions"
            placeholder="Todas. Digite o nome da turma..." :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Situação</label>
          <v-select class="vue_select_drop_size_90" v-model="theoreticalSchedule.status"
            :reduce="option => option.value" :options="[
              { value: 'Programada', label: 'Programada' },
              { value: 'Presente', label: 'Presente' },
              { value: 'Ausente', label: 'Ausente' },
            ]" placeholder="TODOS" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupTheoreticalSchedule = false">Cancelar</vs-button>
            <vs-button class="ml-4" @click="generateReport(2)">Gerar Documento</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- Compromissos -->
    <vs-popup class="popup80" :fullscreen="false" :title="showPdfTitle" :active.sync="popupCommitmentSchedule">
      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <label class="vs-input--label">Atribuído para</label>
          <v-select class="vue_select_drop_size_150" v-model="commitmentSchedule.employees"
            :reduce="option => option.value" multiple :options="employees" placeholder="Selecione"
            :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupCommitmentSchedule = false">Cancelar</vs-button>
            <vs-button class="ml-4" @click="generateReport(3)">Gerar Documento</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- EXAMES -->
    <vs-popup class="popup80" :fullscreen="false" :title="showPdfTitle" :active.sync="popupExamSchedule">
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Aluno(s)</label>
          <div class="mt-0">
            <v-select class="vue_select_drop_size_150" v-model="examSchedule.students"
              @search="debouncedGetSearchStudent" multiple :clearable="false" :filterable="true"
              :reduce="option => option.value" :options="studentOptions" placeholder="Digite CPF ou nome do aluno..."
              :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
        </div>
        <div v-if="examSchedule.type === 'medical_exam' || examSchedule.type === 'psychotechnical'"
          class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Medico(s)</label>
          <v-select class="vue_select_drop_size_150" v-model="examSchedule.employees" :reduce="option => option.value"
            multiple :options="doctors" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div v-if="examSchedule.type === 'practical_exam' || examSchedule.type === 'theoretical_exam'"
          class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Instrutor(es)</label>
          <v-select class="vue_select_drop_size_150" v-model="examSchedule.employees" :reduce="option => option.value"
            multiple :options="employees" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>
      <div class="vx-row">
        <div v-if="examSchedule.type === 'medical_exam' || examSchedule.type === 'psychotechnical'"
          class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">CAC(s)</label>
          <v-select class="vue_select_drop_size_150" v-model="examSchedule.cacs" :reduce="option => option.value"
            multiple :options="cacs" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Situação</label>
          <v-select class="vue_select_drop_size_90" v-model="examSchedule.status" :reduce="option => option.value"
            :options="situationOptions" placeholder="TODOS" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div v-if="examSchedule.type === 'medical_exam' || examSchedule.type === 'psychotechnical'"
          class="vx-col md:w-1/4 w-full mt-0">
          <vs-checkbox v-model="examSchedule.showAddress" class="ml-0 mr-1 mt-8">Mostrar endereço do
            aluno.</vs-checkbox>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupExamSchedule = false">Cancelar</vs-button>
            <vs-button class="ml-4" @click="generateReport(4)">Gerar Documento</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <div class="flex-grow">
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full">
              <vs-input type="date" @change="checkPeriod" v-model="firstPeriod" label="Data Inicial" />
            </div>
            <div class="vx-col md:w-1/3 w-full">
              <vs-input type="date" @change="checkPeriod" v-model="lastPeriod" label="Data Final" />
            </div>
            <div class="vx-col md:w-1/3 w-full">
              <label class="vs-input--label">Ordem</label>
              <v-select :clearable="false" v-model="orderBy" :reduce="option => option.value" :options="[
                { value: 'date', label: 'Data' },
                { value: 'student', label: 'Aluno' },
                { value: 'employee', label: 'Profissional' },
              ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
          </div>
          <span class="text-danger text-sm" v-show="periodError">{{ periodErrorMessage }}</span>
        </div>
        <vs-input label="Nome do Relatório"
          class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-0 ml-4"
          :class="{ 'mb-6': periodError }" v-model="searchQuery" @input="updateSearchQuery"
          placeholder="Busca rápida ..." />
      </div>

      <h3>Relatórios</h3>
      <div class="vx-row mt-4">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-table noDataText="" class="bordered" :data="preDocumentsFiltered" hoverFlat
            style="overflow: -webkit-paged-y;">
            <template slot="thead">
              <vs-th style="font-size: 11px;"><span>TÍTULO</span></vs-th>
              <vs-th style="font-size: 11px;"><span>GERAR</span></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr v-for="(doc, i) in data" :key="i" style="font-size: 12px; opacity: 1 !important">
                <vs-td style="text-align:left"><span class="cursor-default font-semibold">{{ doc.title }}</span></vs-td>
                <vs-td>

                  <div class="flex flex-wrap items-center">
                    <vx-tooltip text="Relatório PDF" position="top" class="mt-2">
                      <svg @click="print(doc, 'PDF')" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" class="svg-hover h-5 w-5 cursor-pointer"
                        aria-hidden="true" focusable="false" width="0.86em" height="1em"
                        style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
                        preserveAspectRatio="xMidYMid meet" viewBox="0 0 1536 1792">
                        <path
                          d="M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z" />
                      </svg>
                    </vx-tooltip>
                    <vx-tooltip text="Relatório Excel" position="top" class="mt-2 ml-2">
                      <svg @click="print(doc, 'EXCEL')" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" class="svg-hover h-5 w-5 cursor-pointer"
                        aria-hidden="true" focusable="false" width="1em" height="1em"
                        style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
                        preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                        <path
                          d="M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z" />
                      </svg>
                    </vx-tooltip>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-table noDataText="" class="bordered" :data="preDocumentsFilteredCol2" hoverFlat
            style="overflow: -webkit-paged-y;">
            <template slot="thead">
              <vs-th style="font-size: 11px;"><span>TÍTULO</span></vs-th>
              <vs-th style="font-size: 11px;"><span>GERAR</span></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr v-for="(doc, i) in data" :key="i" style="font-size: 12px; opacity: 1 !important">
                <vs-td style="text-align:left"><span class="cursor-default font-semibold">{{ doc.title }}</span></vs-td>
                <vs-td>
                  <div class="flex flex-wrap items-center">
                    <vx-tooltip text="Relatório PDF" position="top" class="mt-2">
                      <svg @click="print(doc, 'PDF')" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" class="svg-hover h-5 w-5 cursor-pointer"
                        aria-hidden="true" focusable="false" width="0.86em" height="1em"
                        style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
                        preserveAspectRatio="xMidYMid meet" viewBox="0 0 1536 1792">
                        <path
                          d="M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z" />
                      </svg>
                    </vx-tooltip>
                    <vx-tooltip text="Relatório Excel" position="top" class="mt-2 ml-2">
                      <svg @click="print(doc, 'EXCEL')" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" class="svg-hover h-5 w-5 cursor-pointer"
                        aria-hidden="true" focusable="false" width="1em" height="1em"
                        style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
                        preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                        <path
                          d="M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z" />
                      </svg>
                    </vx-tooltip>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import moduleEmployee from '@/store/employee/moduleEmployee.js'
import moduleVehicle from '@/store/vehicle/moduleVehicle.js'
import moduleCac from '@/store/cac/moduleCac.js'
import moduleDoctor from '@/store/doctor/moduleDoctor.js'
import ExamPassRatePopup from '@/components/report/schedule/popup/ExamPassRatePopup.vue'
import ExamPassRateFinancialPopup from '@/components/report/schedule/popup/ExamPassRateFinancialPopup.vue'

export default {
  components: {
    vSelect,
    ExamPassRatePopup,
    ExamPassRateFinancialPopup
  },

  data() {
    return {
      format: 'PDF',
      activeExamPassRatePopup: false,
      activeExamPassRateFinancialPopup: false,
      periodErrorMessage: 'O período é obrigatório.',
      periodError: true,
      firstPeriod: this.mixinToday(),
      lastPeriod: this.mixinToday(),
      orderBy: 'date',

      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,

      users: [],

      studentOptions: [],
      turmaOptions: [],

      popupPracticalSchedule: false,
      practicalSchedule: {
        students: [],
        employees: [],
        vehicles: [],
        type_vehicle: null,
        status: null
      },

      popupTheoreticalSchedule: false,
      theoreticalSchedule: {
        students: [],
        employees: [],
        turmas: [],
        status: null
      },

      popupCommitmentSchedule: false,
      commitmentSchedule: {
        employees: [],
        title: ''
      },

      popupExamSchedule: false,
      situationOptions: [],
      examSchedule: {
        students: [],
        doctors: [],
        cacs: [],
        status: null,
        type: null,
        showAddress: false
      },

      searchQuery: '',
      preDocumentsFiltered: [],
      preDocuments: [
        { value: 1, title: 'Aula Prática' },
        { value: 2, title: 'Aula Teórica' },
        { value: 3, title: 'Compromissos' }
      ],
      preDocumentsFilteredCol2: [],
      preDocumentsCol2: [
        { value: 4, title: 'Exame Médico' },
        { value: 5, title: 'Exame Prático' },
        { value: 6, title: 'Exame Psicotécnico' },
        { value: 7, title: 'Exame Teórico' },
        { value: 'examPassRate', title: 'Índice de Aprovação de Exame' },
        { value: 'examPassRateFinancial', title: 'Índice de Exame (financeiro)' }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser
    },
    today() {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    employees() {
      return this.$store.getters['employee/forSelect']
    },
    vehicles() {
      return this.$store.getters['vehicle/forSelect']
    },
    doctors() {
      return this.$store.getters['doctor/forSelect']
    },
    cacs() {
      return this.$store.getters['cac/forSelect']
    },
    examPassModel() {
      return {
        firstPeriod: this.firstPeriod,
        lastPeriod: this.lastPeriod
      }
    }
  },
  methods: {
    examPassRate() {
      this.activeExamPassRatePopup = true
    },
    examPassRateFinancial() {
      this.activeExamPassRateFinancialPopup = true
    },
    checkPeriod() {
      if (moment(this.firstPeriod).year() > 1000 && moment(this.lastPeriod).year() > 1000) {
        const difDays = moment(this.lastPeriod).diff(moment(this.firstPeriod), 'days')
        if (difDays < 0) {
          this.periodError = true
          this.periodErrorMessage = 'O período inicial deve ser anterior ao final.'
          return
        } else if (difDays > ((365 * 5) + 1)) {
          this.periodError = true
          this.periodErrorMessage = 'O período não pode ser maior que 5 anos.'
          return
        }
        this.periodError = false
      } else if (!this.firstPeriod && !this.lastPeriod) {
        this.periodError = true
        this.periodErrorMessage = 'O período é obrigatório.'
      }
    },
    async print(doc, type) {
      if (typeof doc.value === 'string') {
        this.format = type
        this[doc.value]()
        return
      }
      if (this.periodError) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Cheque o Período!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }

      try {
        this.type = type
        this.$vs.loading()
        if (doc.value === 1) {

          // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
          const mod = this.user.permissions_user.find(a => a.module === 'spa_report_schedule_practical_class')
          if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('generate'))) {
            this.$vs.notify({
              time: 5000,
              title: 'Aviso',
              text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'center-top'
            })
            this.$nextTick(() => { this.$vs.loading.close() })
            return
          }

          this.showPdfTitle = 'Relatório de Agendamentos de Aulas Práticas'
          this.popupPracticalSchedule = true

        } else if (doc.value === 2) {

          // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
          const mod = this.user.permissions_user.find(a => a.module === 'spa_report_schedule_theoretical_class')
          if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('generate'))) {
            this.$vs.notify({
              time: 5000,
              title: 'Aviso',
              text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'center-top'
            })
            this.$nextTick(() => { this.$vs.loading.close() })
            return
          }

          this.showPdfTitle = 'Relatório de Agendamentos de Aulas Teóricas'
          this.popupTheoreticalSchedule = true

        } else if (doc.value === 3) { // COMPROMISSOS

          this.showPdfTitle = 'Relatório de Agendamentos de Compromissos'
          this.popupCommitmentSchedule = true

        } else if (doc.value === 4 || doc.value === 5 || doc.value === 6 || doc.value === 7) {
          if (doc.value === 4) {

            // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
            const mod = this.user.permissions_user.find(a => a.module === 'spa_report_schedule_medical_exam')
            if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('generate'))) {
              this.$vs.notify({
                time: 5000,
                title: 'Aviso',
                text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
                position: 'center-top'
              })
              this.$nextTick(() => { this.$vs.loading.close() })
              return
            }

            this.showPdfTitle = 'Relatório de Exames Médicos'
            this.examSchedule.type = 'medical_exam'
            this.situationOptions = [
              { value: 'Apto', label: 'Apto' },
              { value: 'Inapto', label: 'Inapto' },
              { value: 'Apto Com Restrições', label: 'Apto Com Restrições' },
              { value: 'Pendente', label: 'Pendente' }
            ]
          } else if (doc.value === 5) {

            // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
            const mod = this.user.permissions_user.find(a => a.module === 'spa_report_schedule_practical_exam')
            if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('generate'))) {
              this.$vs.notify({
                time: 5000,
                title: 'Aviso',
                text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
                position: 'center-top'
              })
              this.$nextTick(() => { this.$vs.loading.close() })
              return
            }
            this.showPdfTitle = 'Relatório de Exames Práticos'
            this.examSchedule.type = 'practical_exam'
            this.situationOptions = [
              { value: 'Apto', label: 'Apto' },
              { value: 'Inapto', label: 'Inapto' },
              { value: 'Apto Com Restrições', label: 'Aguardando Teste' }
            ]
          } else if (doc.value === 6) {
            // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
            const mod = this.user.permissions_user.find(a => a.module === 'spa_report_schedule_psychotechnical_exam')
            if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('generate'))) {
              this.$vs.notify({
                time: 5000,
                title: 'Aviso',
                text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
                position: 'center-top'
              })
              this.$nextTick(() => { this.$vs.loading.close() })
              return
            }
            this.showPdfTitle = 'Relatório de Exames Psicotécnicos'
            this.examSchedule.type = 'psychotechnical'
            this.situationOptions = [
              { value: 'Apto', label: 'Apto' },
              { value: 'Inapto', label: 'Inapto' },
              { value: 'Inapto Temporário', label: 'Inapto Temporário' },
              { value: 'Pendente', label: 'Pendente' }
            ]
          } else if (doc.value === 7) {
            // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
            const mod = this.user.permissions_user.find(a => a.module === 'spa_report_schedule_theoretical_exam')
            if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('generate'))) {
              this.$vs.notify({
                time: 5000,
                title: 'Aviso',
                text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
                position: 'center-top'
              })
              this.$nextTick(() => { this.$vs.loading.close() })
              return
            }
            this.showPdfTitle = 'Relatório de Exames Teóricos'
            this.examSchedule.type = 'theoretical_exam'
            this.situationOptions = [
              { value: 'Apto', label: 'Apto' },
              { value: 'Inapto', label: 'Inapto' },
              { value: 'Aguardando Teste', label: 'Aguardando Teste' }
            ]
          }

          this.popupExamSchedule = true
        }
        this.$nextTick(() => { this.$vs.loading.close() })
      } catch (error) {
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    // BAIXA O ARQUIVO EXCEL OU WORD
    makeBlobDoc(blob, filename) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${filename || 'Relatório'}.xlsx`
      link.click()
    },

    async generateReport(id) {
      this.$vs.loading()
      try {
        if (id === 1) { // AULAS PRÁTICAS
          const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/schedule/practicalClass/report`, {
            firstPeriod: this.firstPeriod,
            lastPeriod: this.lastPeriod,
            orderBy: this.orderBy,
            format: this.type,
            payload: this.practicalSchedule
          }, { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null })

          if (this.type === 'PDF') {
            // if (resp.data.background == true) {
              
            //   // this.srcPdf = `data:application/pdf;base64,${resp.data.content}`;
            //   this.popupShowPdf = false;
              
            //   this.$vs.notify({
            //     time: 10000,
            //     title: 'Aviso',
            //     text: resp.data.content,
            //     iconPack: 'feather',
            //     icon: 'icon-alert-check',
            //     color: 'success',
            //     position: 'center-top',
            //     width: '1024px'
            //   });
            //   this.$nextTick(() => { this.$vs.loading.close() })
            // } else {
              this.srcPdf = `data:application/pdf;base64,${resp.data}`;
              this.popupShowPdf = true;
            // }
          } else if (this.type === 'EXCEL') {
            const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            this.makeBlobDoc(blob, 'Relatório de Agendamentos de Aulas Práticas')
          }
          this.popupPracticalSchedule = false
        } else if (id === 2) { // AULAS TEÓRICAS
          const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/schedule/theoreticalClass/report`, {
            firstPeriod: this.firstPeriod,
            lastPeriod: this.lastPeriod,
            orderBy: this.orderBy,
            format: this.type,
            payload: this.theoreticalSchedule
          }, { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null })

          if (this.type === 'PDF') {
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (this.type === 'EXCEL') {
            const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            this.makeBlobDoc(blob, 'Relatório de Agendamentos de Aulas Teóricas')
          }
          this.popupTheoreticalSchedule = false

        } else if (id === 3) { // COMPROMISSOS
          const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/schedule/commitments/report`, {
            firstPeriod: this.firstPeriod,
            lastPeriod: this.lastPeriod,
            orderBy: this.orderBy,
            format: this.type,
            payload: this.commitmentSchedule
          }, { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null })

          if (this.type === 'PDF') {
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (this.type === 'EXCEL') {
            const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            this.makeBlobDoc(blob, 'Relatório de Agendamentos de Aulas Teóricas')
          }
          this.popupTheoreticalSchedule = false


        } else if (id === 4) { // EXAMES
          const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/schedule/examSchedule/report`, {
            firstPeriod: this.firstPeriod,
            lastPeriod: this.lastPeriod,
            orderBy: this.orderBy,
            format: this.type,
            payload: this.examSchedule
          }, { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null })

          if (this.type === 'PDF') {
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (this.type === 'EXCEL') {
            const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            this.makeBlobDoc(blob, this.showPdfTitle)
          }
          this.popupExamSchedule = false
        }

        this.$nextTick(() => {
          this.$vs.loading.close()
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: error,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    updateSearchQuery(val) {
      this.debouncedGetSearchResult(val)
    },
    getSearchResult(searchVal) {
      this.preDocumentsFiltered = JSON.parse(JSON.stringify(this.preDocuments.filter(a => a.title.toUpperCase().indexOf(searchVal.toUpperCase()) > -1)))
      this.preDocumentsFilteredCol2 = JSON.parse(JSON.stringify(this.preDocumentsCol2.filter(a => a.title.toUpperCase().indexOf(searchVal.toUpperCase()) > -1)))
    },
    fetchStudentOptions(search, loading) {
      if (search.length) {
        loading(true)
        this.$http.post(`${process.env.VUE_APP_API_URL}/student/search`, { kw: search, type: 'STUDENT' })
          .then(function (response) {
            for (const k in response.data) {
              //INCLUIR SOMENTE OS ALUNOS QUE NÃO EXISTEM
              const idx = this.studentOptions.findIndex((e) => e.value === response.data[k].id)
              if (idx === -1) {
                this.studentOptions.push({
                  value: response.data[k].id,
                  label: `${response.data[k].name} ${response.data[k].cpf || ''}`
                })
              }
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    },
    fetchTurmaOptions(search, loading) {
      if (search.length) {
        loading(true)
        this.$http.post(`${process.env.VUE_APP_API_URL}/turma/search`, { kw: search })
          .then(function (response) {
            for (const k in response.data) {
              //INCLUIR SOMENTE OS ALUNOS QUE NÃO EXISTEM
              const idx = this.turmaOptions.findIndex((e) => e.value === response.data[k].id)
              if (idx === -1) {
                this.turmaOptions.push({
                  value: response.data[k].id,
                  label: response.data[k].name,
                  data: response.data[k]
                })
              }
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    },
    async fetchUsers() {
      const resp = await this.$http.get(`${process.env.VUE_APP_API_URL}/users/getAll`)
      for (const k in resp.data) {
        this.users.push({
          value: resp.data[k].id,
          label: `${resp.data[k].name}`
        })
      }
    }
  },
  created() {
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
    this.debouncedGetSearchStudent = _.debounce(this.fetchStudentOptions, 600)
    this.debouncedGetSearchTurma = _.debounce(this.fetchTurmaOptions, 600)

    this.fetchUsers()

    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleVehicle.isRegistered) {
      this.$store.registerModule('vehicle', moduleVehicle)
      moduleVehicle.isRegistered = true
    }
    this.$store.dispatch('vehicle/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleCac.isRegistered) {
      this.$store.registerModule('cac', moduleCac)
      moduleCac.isRegistered = true
    }
    this.$store.dispatch('cac/fetchAll')

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule('doctor', moduleDoctor)
      moduleDoctor.isRegistered = true
    }
    this.$store.dispatch('doctor/fetchAll')

    this.preDocumentsFiltered = JSON.parse(JSON.stringify(this.preDocuments))
    this.preDocumentsFilteredCol2 = JSON.parse(JSON.stringify(this.preDocumentsCol2))
    this.checkPeriod()
  }
}
</script>

<style lang="scss">
.con-vs-popup .vs-popup {
  width: 100%;
}

.noOverflow .vs-popup--content {
  overflow: hidden;
}

.popup80 .vs-popup {
  width: 80% !important;
}

.popup50 .vs-popup {
  width: 50% !important;
}

.popup60 .vs-popup {
  width: 60% !important;
}

.vue_select_drop_size_90 .vs__dropdown-menu {
  max-height: 90px;
}

.vue_select_drop_size_160 .vs__dropdown-menu {
  max-height: 160px;
}

.vue_select_drop_size_150 .vs__dropdown-menu {
  max-height: 150px;
}

#student-documents th {
  background-color: #b4b4b4;
}

#student-documents th .vs-table-text {
  color: #158482;
  cursor: default;
}

#student-documents .vs-con-table .vs-con-tbody {
  border: 1px solid #c0c0c0;
}

.svg-hover:hover {
  fill: #158482;
}

.svg-hover {
  fill: #626262;
}

#student-documents td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}

#student-documents td:hover {
  border-bottom: 1px solid #353434;
}

#student-documents td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}

#student-documents .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}

#student-documents .vs-table--thead {
  border: 1px solid transparent;
}

// .icon {
//   font-size: 16px;
// }
#student-documents .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
  padding: 6px;
}

#student-documents .ps__scrollbar-y-rail {
  z-index: 99999;
}

.vs-tooltip {
  z-index: 99999;
}

.under:hover {
  text-decoration: underline;
}
</style>
