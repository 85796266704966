<template>
  <vs-row class="w-full">
    <vs-col class="w-full">
      <label class="vs-input--label">{{ label }}</label>
      <v-select
        v-model="single"
        @input="inputSelect"
        @open="$emit('open-sigselect')"
        :multiple="multiple"
        :clearable="false"
        :filterable="false"
        :reduce="(option) => option.value"
        :options="options"
        :data-vv-as="label"
        v-validate.initial="validate"
        placeholder="TODOS"
        name="single"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
      <span class="text-danger text-sm" v-show="errors.has('single')">{{
        errors.first('single')
      }}</span>
    </vs-col>
  </vs-row>
</template>

<script>
// Store Module
import axios from '@/axios.js'
import _ from 'lodash'

export default {
  props: {
    value: {},
    multiple: {},
    label: {
      default: 'Usuário'
    },
    validate: {}
  },
  data () {
    return {
      single: null,
      options: []
    }
  },
  methods: {
    inputSelect () {
      this.value.data = this.single
    },
    fetchOptions (search) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/users/getAll`)
        .then(
          function (response) {
            this.options = []
            response.data.forEach(e => {
              this.options.push({
                value: e.id,
                label: e.name
              })
            })

          }.bind(this)
        )
        .catch(function (error) {
          console.error(error)
        })
    },
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('single')
    }
  },
  created () {
    this.debouncedGetSearch = _.debounce(this.fetchOptions, 600)
    if (this.options.length === 0) {
      this.debouncedGetSearch()
    }
  }
}
</script>
