var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "student-documents" } },
    [
      _c("popup-pdf", { ref: "popupPdf", attrs: { format: _vm.typeReport } }),
      _c("contract-employee-commission-modal", {
        attrs: { active: _vm.contractEmployeeComissionPopup },
        on: {
          "update:active": function ($event) {
            _vm.contractEmployeeComissionPopup = $event
          },
        },
        model: {
          value: _vm.params,
          callback: function ($$v) {
            _vm.params = $$v
          },
          expression: "params",
        },
      }),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupShowPdf,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowPdf = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
      _c(
        "sig-prompt",
        {
          attrs: {
            active: _vm.popupEmployee,
            title: "Serviço por funcionário",
          },
          on: {
            cancel: function ($event) {
              _vm.popupEmployee = false
            },
            accept: () => {
              this.popupEmployee = false
              this.generateReport(11)
            },
          },
        },
        [
          _c("select-employee", {
            model: {
              value: _vm.employee,
              callback: function ($$v) {
                _vm.employee = $$v
              },
              expression: "employee",
            },
          }),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup50",
          attrs: {
            title: _vm.individualCashier.title,
            active: _vm.popupIndividualCashier,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupIndividualCashier = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Responsável"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "vue_select_drop_size_150",
                  attrs: {
                    clearable: false,
                    "data-vv-as": "Responsável",
                    "data-vv-scope": "individualCashier",
                    name: "user_id",
                    options: _vm.users,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.individualCashier.user_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.individualCashier, "user_id", $$v)
                    },
                    expression: "individualCashier.user_id",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("individualCashier.user_id"),
                        expression: "errors.has('individualCashier.user_id')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Campo Obrigatório.")]
                ),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupIndividualCashier = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: { disabled: !_vm.validateIndividualCashier },
                      on: {
                        click: function ($event) {
                          return _vm.generateReport(7)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: "Relatório de Estornos",
            active: _vm.popupChargeback,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupChargeback = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Plano de Contas"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    options: _vm.accountPlans,
                    placeholder: "TODOS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.chargeback.account_plan_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.chargeback, "account_plan_id", $$v)
                    },
                    expression: "chargeback.account_plan_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipo de Pagamento"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    options: _vm.typePayments,
                    placeholder: "TODOS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.chargeback.type_payment_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.chargeback, "type_payment_id", $$v)
                    },
                    expression: "chargeback.type_payment_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Entrada/Saída"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    options: [
                      { value: "CREDIT", label: "Entradas" },
                      { value: "DEBIT", label: "Saídas" },
                    ],
                    placeholder: "TODOS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.chargeback.income_expense,
                    callback: function ($$v) {
                      _vm.$set(_vm.chargeback, "income_expense", $$v)
                    },
                    expression: "chargeback.income_expense",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Serviços Contratados"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    options: _vm.servicePackages,
                    multiple: "",
                    placeholder: "TODOS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.chargeback.servicePackages,
                    callback: function ($$v) {
                      _vm.$set(_vm.chargeback, "servicePackages", $$v)
                    },
                    expression: "chargeback.servicePackages",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupChargeback = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      on: {
                        click: function ($event) {
                          return _vm.generateReport(6)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupCustomizable,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupCustomizable = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Título" },
                  model: {
                    value: _vm.customizable.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.customizable, "title", $$v)
                    },
                    expression: "customizable.title",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Data"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { label: "Data de Vencimento", value: "due_date" },
                      { label: "Data de Pagamento", value: "payment_date" },
                      { label: "Data de Lançamento", value: "release_date" },
                    ],
                    placeholder: "TODOS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.customizable.for_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.customizable, "for_date", $$v)
                    },
                    expression: "customizable.for_date",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Situação"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    clearable: true,
                    reduce: (option) => option.value,
                    options: [
                      { label: "Pagos", value: "PAID" },
                      { label: "Não pago", value: "PENDING" },
                    ],
                    placeholder: "TODAS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.customizable.situation,
                    callback: function ($$v) {
                      _vm.$set(_vm.customizable, "situation", $$v)
                    },
                    expression: "customizable.situation",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Contas"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    multiple: "",
                    reduce: (option) => option.value,
                    options: _vm.accounts,
                    placeholder: "TODAS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.customizable.accounts,
                    callback: function ($$v) {
                      _vm.$set(_vm.customizable, "accounts", $$v)
                    },
                    expression: "customizable.accounts",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Planos de Conta"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    multiple: "",
                    reduce: (option) => option.value,
                    options: _vm.accountPlans,
                    placeholder: "TODOS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.customizable.accountPlans,
                    callback: function ($$v) {
                      _vm.$set(_vm.customizable, "accountPlans", $$v)
                    },
                    expression: "customizable.accountPlans",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Pessoas"),
                ]),
                _c("v-select", {
                  attrs: {
                    multiple: "",
                    clearable: true,
                    filterable: true,
                    reduce: (option) => option.value,
                    options: _vm.studentOptions,
                    placeholder: "TODAS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: { search: _vm.debouncedGetSearchStudent },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.customizable.persons,
                    callback: function ($$v) {
                      _vm.$set(_vm.customizable, "persons", $$v)
                    },
                    expression: "customizable.persons",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Pessoas - Situação"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    options: [
                      { label: "ATIVO", value: true },
                      { label: "INATIVO", value: false },
                      { label: "TODAS", value: null },
                    ],
                    placeholder: "TODAS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.customizable.student_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.customizable, "student_status", $$v)
                    },
                    expression: "customizable.student_status",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("user-search-select", {
                  attrs: { multiple: "" },
                  model: {
                    value: _vm.responsible,
                    callback: function ($$v) {
                      _vm.responsible = $$v
                    },
                    expression: "responsible",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Formas de Pagamento"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    multiple: "",
                    reduce: (option) => option.value,
                    options: _vm.typePayments,
                    placeholder: "TODAS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.customizable.typePayments,
                    callback: function ($$v) {
                      _vm.$set(_vm.customizable, "typePayments", $$v)
                    },
                    expression: "customizable.typePayments",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Serviços"),
                ]),
                _c("v-select", {
                  attrs: {
                    multiple: "",
                    reduce: (option) => option.value,
                    options: _vm.servicePackages,
                    placeholder: "TODOS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.customizable.services,
                    callback: function ($$v) {
                      _vm.$set(_vm.customizable, "services", $$v)
                    },
                    expression: "customizable.services",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Vencido/Não Vencido"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    options: [
                      { label: "Vencido", value: "expired" },
                      { label: "Não Vencido", value: "not_expired" },
                    ],
                    placeholder: "TODAS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.customizable.situation2,
                    callback: function ($$v) {
                      _vm.$set(_vm.customizable, "situation2", $$v)
                    },
                    expression: "customizable.situation2",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Entrada/Saída"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    options: [
                      { label: "Entrada", value: "CREDIT" },
                      { label: "Saída", value: "DEBIT" },
                    ],
                    placeholder: "TODAS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.customizable.income_expense,
                    callback: function ($$v) {
                      _vm.$set(_vm.customizable, "income_expense", $$v)
                    },
                    expression: "customizable.income_expense",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Ordem"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { label: "Nome", value: "name" },
                      { label: "Nº de recibo", value: "receipt_number" },
                      { label: "Dt. de pagamento", value: "payday" },
                      { label: "Dt. de vencimento", value: "expiration_date" },
                      { label: "Dt. de lançamento", value: "data_lancamento" },
                      { label: "Tipo", value: "type" },
                      { label: "Situação", value: "situation" },
                      { label: "Valor da parcela", value: "value_parcel" },
                      { label: "Valor da pago", value: "value_pay" },
                    ],
                    placeholder: "TODAS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.customizable.order_by,
                    callback: function ($$v) {
                      _vm.$set(_vm.customizable, "order_by", $$v)
                    },
                    expression: "customizable.order_by",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Formato"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { label: "Retrato", value: "pdf_retrato" },
                      { label: "Paisagem", value: "pdf_paisagem" },
                    ],
                    placeholder: "TODAS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.customizable.format,
                    callback: function ($$v) {
                      _vm.$set(_vm.customizable, "format", $$v)
                    },
                    expression: "customizable.format",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "FileTextIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Dados Financeiros Mostrados"),
              ]),
            ],
            1
          ),
          _c("hr", { staticClass: "mb-4" }),
          _vm._l(_vm.customizableFinancialDataList, function (reason, i) {
            return _c("div", { key: `${i}_reason`, staticClass: "vx-row" }, [
              reason.value1
                ? _c("div", { staticClass: "vx-col md:w-1/5 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": reason.value1 },
                              model: {
                                value: _vm.customizable.financialFields,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.customizable,
                                    "financialFields",
                                    $$v
                                  )
                                },
                                expression: "customizable.financialFields",
                              },
                            },
                            [_vm._v(_vm._s(reason.text1))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              reason.value2
                ? _c("div", { staticClass: "vx-col md:w-1/5 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": reason.value2 },
                              model: {
                                value: _vm.customizable.financialFields,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.customizable,
                                    "financialFields",
                                    $$v
                                  )
                                },
                                expression: "customizable.financialFields",
                              },
                            },
                            [_vm._v(_vm._s(reason.text2))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              reason.value3
                ? _c("div", { staticClass: "vx-col md:w-1/5 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": reason.value3 },
                              model: {
                                value: _vm.customizable.financialFields,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.customizable,
                                    "financialFields",
                                    $$v
                                  )
                                },
                                expression: "customizable.financialFields",
                              },
                            },
                            [_vm._v(_vm._s(reason.text3))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              reason.value4
                ? _c("div", { staticClass: "vx-col md:w-1/5 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": reason.value4 },
                              model: {
                                value: _vm.customizable.financialFields,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.customizable,
                                    "financialFields",
                                    $$v
                                  )
                                },
                                expression: "customizable.financialFields",
                              },
                            },
                            [_vm._v(_vm._s(reason.text4))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              reason.value5
                ? _c("div", { staticClass: "vx-col md:w-1/5 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": reason.value5 },
                              model: {
                                value: _vm.customizable.financialFields,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.customizable,
                                    "financialFields",
                                    $$v
                                  )
                                },
                                expression: "customizable.financialFields",
                              },
                            },
                            [_vm._v(_vm._s(reason.text5))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          }),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "FileTextIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Dados Gerais da Pessoa Mostrados"),
              ]),
            ],
            1
          ),
          _c("hr", { staticClass: "mb-4" }),
          _vm._l(_vm.personGeneralDataList, function (document, i) {
            return _c("div", { key: i, staticClass: "vx-row" }, [
              document.value1
                ? _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value1 },
                              model: {
                                value: _vm.customizable.personGeneralData,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.customizable,
                                    "personGeneralData",
                                    $$v
                                  )
                                },
                                expression: "customizable.personGeneralData",
                              },
                            },
                            [_vm._v(_vm._s(document.text1))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              document.value2
                ? _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value2 },
                              model: {
                                value: _vm.customizable.personGeneralData,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.customizable,
                                    "personGeneralData",
                                    $$v
                                  )
                                },
                                expression: "customizable.personGeneralData",
                              },
                            },
                            [_vm._v(_vm._s(document.text2))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              document.value3
                ? _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value3 },
                              model: {
                                value: _vm.customizable.personGeneralData,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.customizable,
                                    "personGeneralData",
                                    $$v
                                  )
                                },
                                expression: "customizable.personGeneralData",
                              },
                            },
                            [_vm._v(_vm._s(document.text3))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              document.value4
                ? _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value4 },
                              model: {
                                value: _vm.customizable.personGeneralData,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.customizable,
                                    "personGeneralData",
                                    $$v
                                  )
                                },
                                expression: "customizable.personGeneralData",
                              },
                            },
                            [_vm._v(_vm._s(document.text4))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          }),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "FileTextIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Dados de Endereço da Pessoa Mostrados"),
              ]),
            ],
            1
          ),
          _c("hr", { staticClass: "mb-4" }),
          _vm._l(_vm.personAddressDataList, function (document, i) {
            return _c("div", { key: `${i}_address`, staticClass: "vx-row" }, [
              document.value1
                ? _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value1 },
                              model: {
                                value: _vm.customizable.personAddressData,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.customizable,
                                    "personAddressData",
                                    $$v
                                  )
                                },
                                expression: "customizable.personAddressData",
                              },
                            },
                            [_vm._v(_vm._s(document.text1))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              document.value2
                ? _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value2 },
                              model: {
                                value: _vm.customizable.personAddressData,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.customizable,
                                    "personAddressData",
                                    $$v
                                  )
                                },
                                expression: "customizable.personAddressData",
                              },
                            },
                            [_vm._v(_vm._s(document.text2))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              document.value3
                ? _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value3 },
                              model: {
                                value: _vm.customizable.personAddressData,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.customizable,
                                    "personAddressData",
                                    $$v
                                  )
                                },
                                expression: "customizable.personAddressData",
                              },
                            },
                            [_vm._v(_vm._s(document.text3))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              document.value3
                ? _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value4 },
                              model: {
                                value: _vm.customizable.personAddressData,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.customizable,
                                    "personAddressData",
                                    $$v
                                  )
                                },
                                expression: "customizable.personAddressData",
                              },
                            },
                            [_vm._v(_vm._s(document.text4))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          }),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupCustomizable = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      on: {
                        click: function ($event) {
                          return _vm.generateReport(10)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupAccountPlan,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupAccountPlan = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Plano de Contas"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    multiple: "",
                    options: _vm.accountPlans,
                    placeholder: "TODOS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.accountPlan.accountPlans,
                    callback: function ($$v) {
                      _vm.$set(_vm.accountPlan, "accountPlans", $$v)
                    },
                    expression: "accountPlan.accountPlans",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupAccountPlan = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      on: {
                        click: function ($event) {
                          return _vm.generateReport(9)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "vx-card p-6" }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap items-center" },
          [
            _c("div", { staticClass: "flex-grow" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "date", label: "Data Inicial" },
                      on: { change: _vm.checkPeriod },
                      model: {
                        value: _vm.firstPeriod,
                        callback: function ($$v) {
                          _vm.firstPeriod = $$v
                        },
                        expression: "firstPeriod",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "date", label: "Data Final" },
                      on: { change: _vm.checkPeriod },
                      model: {
                        value: _vm.lastPeriod,
                        callback: function ($$v) {
                          _vm.lastPeriod = $$v
                        },
                        expression: "lastPeriod",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.periodError,
                      expression: "periodError",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.periodErrorMessage))]
              ),
            ]),
            _c("vs-input", {
              staticClass:
                "sm:mr-4 mr-0 sm:w-1/3 w-full sm:order-normal order-3 sm:mt-0 mt-0 ml-4",
              class: { "mb-6": _vm.periodError },
              attrs: {
                label: "Nome do Relatório",
                placeholder: "Busca rápida ...",
              },
              on: { input: _vm.updateSearchQuery },
              model: {
                value: _vm.searchQuery,
                callback: function ($$v) {
                  _vm.searchQuery = $$v
                },
                expression: "searchQuery",
              },
            }),
          ],
          1
        ),
        _c("h3", [_vm._v("Relatórios")]),
        _c("div", { staticClass: "vx-row mt-4" }, [
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full mt-0" },
            [
              _c(
                "vs-table",
                {
                  staticClass: "bordered",
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: {
                    noDataText: "",
                    data: _vm.preDocumentsFiltered,
                    hoverFlat: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _vm._l(data, function (doc, i) {
                          return _c(
                            "vs-tr",
                            {
                              key: i,
                              staticStyle: {
                                "font-size": "12px",
                                opacity: "1 !important",
                              },
                            },
                            [
                              _c(
                                "vs-td",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "cursor-default font-semibold",
                                    },
                                    [_vm._v(_vm._s(doc.title))]
                                  ),
                                ]
                              ),
                              _c("vs-td", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex flex-wrap items-center",
                                  },
                                  [
                                    _c(
                                      "vx-tooltip",
                                      {
                                        staticClass: "mt-2",
                                        attrs: {
                                          text: "Relatório PDF",
                                          position: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "svg-hover h-5 w-5 cursor-pointer",
                                            staticStyle: {
                                              "-ms-transform": "rotate(360deg)",
                                              "-webkit-transform":
                                                "rotate(360deg)",
                                              transform: "rotate(360deg)",
                                            },
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              "xmlns:xlink":
                                                "http://www.w3.org/1999/xlink",
                                              "aria-hidden": "true",
                                              focusable: "false",
                                              width: "0.86em",
                                              height: "1em",
                                              preserveAspectRatio:
                                                "xMidYMid meet",
                                              viewBox: "0 0 1536 1792",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.print(doc, "PDF")
                                              },
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vx-tooltip",
                                      {
                                        staticClass: "mt-2 ml-2",
                                        attrs: {
                                          text: "Relatório Excel",
                                          position: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "svg-hover h-5 w-5 cursor-pointer",
                                            staticStyle: {
                                              "-ms-transform": "rotate(360deg)",
                                              "-webkit-transform":
                                                "rotate(360deg)",
                                              transform: "rotate(360deg)",
                                            },
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              "xmlns:xlink":
                                                "http://www.w3.org/1999/xlink",
                                              "aria-hidden": "true",
                                              focusable: "false",
                                              width: "1em",
                                              height: "1em",
                                              preserveAspectRatio:
                                                "xMidYMid meet",
                                              viewBox: "0 0 24 24",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.print(doc, "EXCEL")
                                              },
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("TÍTULO")]),
                      ]),
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("GERAR")]),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full mt-0" },
            [
              _c(
                "vs-table",
                {
                  staticClass: "bordered",
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: {
                    noDataText: "",
                    data: _vm.preDocumentsFilteredCol2,
                    hoverFlat: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _vm._l(data, function (doc, i) {
                          return _c(
                            "vs-tr",
                            {
                              key: i,
                              staticStyle: {
                                "font-size": "12px",
                                opacity: "1 !important",
                              },
                            },
                            [
                              _c(
                                "vs-td",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "cursor-default font-semibold",
                                    },
                                    [_vm._v(_vm._s(doc.title))]
                                  ),
                                ]
                              ),
                              _c("vs-td", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex flex-wrap items-center",
                                  },
                                  [
                                    _c(
                                      "vx-tooltip",
                                      {
                                        staticClass: "mt-2",
                                        attrs: {
                                          text: "Relatório PDF",
                                          position: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "svg-hover h-5 w-5 cursor-pointer",
                                            staticStyle: {
                                              "-ms-transform": "rotate(360deg)",
                                              "-webkit-transform":
                                                "rotate(360deg)",
                                              transform: "rotate(360deg)",
                                            },
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              "xmlns:xlink":
                                                "http://www.w3.org/1999/xlink",
                                              "aria-hidden": "true",
                                              focusable: "false",
                                              width: "0.86em",
                                              height: "1em",
                                              preserveAspectRatio:
                                                "xMidYMid meet",
                                              viewBox: "0 0 1536 1792",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.print(doc, "PDF")
                                              },
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vx-tooltip",
                                      {
                                        staticClass: "mt-2 ml-2",
                                        attrs: {
                                          text: "Relatório Excel",
                                          position: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "svg-hover h-5 w-5 cursor-pointer",
                                            staticStyle: {
                                              "-ms-transform": "rotate(360deg)",
                                              "-webkit-transform":
                                                "rotate(360deg)",
                                              transform: "rotate(360deg)",
                                            },
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              "xmlns:xlink":
                                                "http://www.w3.org/1999/xlink",
                                              "aria-hidden": "true",
                                              focusable: "false",
                                              width: "1em",
                                              height: "1em",
                                              preserveAspectRatio:
                                                "xMidYMid meet",
                                              viewBox: "0 0 24 24",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.print(doc, "EXCEL")
                                              },
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("TÍTULO")]),
                      ]),
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("GERAR")]),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }