<template>
  <div>
    <popup-pdf ref="popupPdf"  @show="$emit('show')" :format="format" title="Índice de exames" />
    <div class="vx-row">
        <select-type-schedule
          v-model="type"
          :only="['theoretical_exam', 'practical_exam']"
          placeholder="TODOS"
          class="vx-col md:w-1/2"
          appendToBody
        />
        <select-status-schedule
          appendToBody
          v-model="status" multiple
          placeholder="TODAS"
          class="vx-col md:w-1/2"
        />
    </div>
    <div class="vx-row">
      <select-employee v-model="employees"
      class="vx-col md:w-1/2"
      :use-id="true" label="Instrutor"
      :roles="['theoretical_instructor', 'practical_instructor', 'practical_theoretical_instructor']"
      placeholder="TODOS"
      appendToBody
      multiple />
      <select-cnh-category
      class="vx-col md:w-1/2"
      v-model="cnhCategory"
      placeholder="TODAS"
      appendToBody
      multiple />
    </div>
      <vs-divider />
    <div class="flex flex-row-reverse">
      <vs-button @click="createReport">Confirmar</vs-button>
    </div>
  </div>
</template>

<script>
import SelectEmployee from '@/components/register/employee/SelectEmployee'
import SelectCnhCategory from '@/components/general/SelectCnhCategory'
import SelectTypeSchedule from '@/components/schedule/SelectTypeSchedule'
import SelectStatusSchedule from '@/components/schedule/SelectStatusSchedule'
import PopupPdf from '@/components/report/pdf/PopupPdf.vue'

export default {
  components: {
    SelectEmployee,
    SelectCnhCategory,
    SelectTypeSchedule,
    SelectStatusSchedule,
    PopupPdf
  },
  props: {
    value: {},
    format: {}
  },
  data () {
    return {
      type: '',
      status: [],
      employees: [],
      cnhCategory: []
    }
  },
  methods: {
    createReport () {
      this.$refs.popupPdf.post({
        url:  `${process.env.VUE_APP_API_URL}/report/exam/practical/passRate`,
        payload: {
          type: this.type,
          financial: true,
          format: this.format,
          status: this.status,
          employees: this.employees,
          name: 'Índice de aprovação de exame',
          cnhCategory: this.cnhCategory,
          ...this.value
        }
      })
    }
  }
}
</script>

<style>

</style>
