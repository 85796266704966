var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-row",
    { staticClass: "w-full" },
    [
      _c(
        "vs-col",
        { staticClass: "w-full" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
          _c("v-select", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate.initial",
                value: _vm.validate,
                expression: "validate",
                modifiers: { initial: true },
              },
            ],
            attrs: {
              multiple: _vm.multiple,
              clearable: false,
              filterable: false,
              reduce: (option) => option.value,
              options: _vm.options,
              "data-vv-as": _vm.label,
              placeholder: "TODOS",
              name: "single",
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            on: {
              input: _vm.inputSelect,
              open: function ($event) {
                return _vm.$emit("open-sigselect")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "no-options",
                fn: function ({}) {
                  return [_vm._v(" Nenhum resultado encontrado. ")]
                },
              },
            ]),
            model: {
              value: _vm.single,
              callback: function ($$v) {
                _vm.single = $$v
              },
              expression: "single",
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("single"),
                  expression: "errors.has('single')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(_vm._s(_vm.errors.first("single")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }