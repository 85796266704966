var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("popup-pdf", {
        ref: "popupPdf",
        attrs: { format: _vm.format, title: "Índice de exames" },
        on: {
          show: function ($event) {
            return _vm.$emit("show")
          },
        },
      }),
      _c(
        "div",
        { staticClass: "vx-row" },
        [
          _c("select-type-schedule", {
            staticClass: "vx-col md:w-1/2",
            attrs: {
              only: ["theoretical_exam", "practical_exam"],
              placeholder: "TODOS",
              appendToBody: "",
            },
            model: {
              value: _vm.type,
              callback: function ($$v) {
                _vm.type = $$v
              },
              expression: "type",
            },
          }),
          _c("select-status-schedule", {
            staticClass: "vx-col md:w-1/2",
            attrs: { appendToBody: "", multiple: "", placeholder: "TODAS" },
            model: {
              value: _vm.status,
              callback: function ($$v) {
                _vm.status = $$v
              },
              expression: "status",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-row" },
        [
          _c("select-employee", {
            staticClass: "vx-col md:w-1/2",
            attrs: {
              "use-id": true,
              label: "Instrutor",
              roles: [
                "theoretical_instructor",
                "practical_instructor",
                "practical_theoretical_instructor",
              ],
              placeholder: "TODOS",
              appendToBody: "",
              multiple: "",
            },
            model: {
              value: _vm.employees,
              callback: function ($$v) {
                _vm.employees = $$v
              },
              expression: "employees",
            },
          }),
          _c("select-cnh-category", {
            staticClass: "vx-col md:w-1/2",
            attrs: { placeholder: "TODAS", appendToBody: "", multiple: "" },
            model: {
              value: _vm.cnhCategory,
              callback: function ($$v) {
                _vm.cnhCategory = $$v
              },
              expression: "cnhCategory",
            },
          }),
        ],
        1
      ),
      _c("vs-divider"),
      _c(
        "div",
        { staticClass: "flex flex-row-reverse" },
        [
          _c("vs-button", { on: { click: _vm.createReport } }, [
            _vm._v("Confirmar"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }