var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "reports-tabs" } },
    [
      _c("vx-card", [
        _c(
          "div",
          {
            staticClass: "tabs-container px-6 pt-6",
            attrs: { slot: "no-body" },
            slot: "no-body",
          },
          [
            _c(
              "vs-tabs",
              {
                staticClass: "tab-action-btn-fill-conatiner",
                model: {
                  value: _vm.activeTab,
                  callback: function ($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab",
                },
              },
              [
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Alunos",
                      "icon-pack": "feather",
                      icon: "icon-users",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("students", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Financeiro",
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("financial", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "CNH",
                      "icon-pack": "feather",
                      icon: "icon-layout",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("cnh", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Protocolos",
                      "icon-pack": "feather",
                      icon: "icon-file-text",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("protocols", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Agendas",
                      "icon-pack": "feather",
                      icon: "icon-calendar",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("schedules", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Serviços",
                      "icon-pack": "feather",
                      icon: "icon-check-square",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("services", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }