<template>
  <div>
    <popup-pdf ref="popupPdf"  @show="$emit('show')" />
    <div class="vx-row">
        <select-type-schedule v-model="type" :only="['theoretical_exam', 'practical_exam']"  placeholder="TODOS" class="vx-col md:w-1/2" appendToBody />
        <select-status-schedule v-model="status" multiple placeholder="TODAS" class="vx-col md:w-1/2" appendToBody />
    </div>
      <vs-divider />
    <div class="flex flex-row-reverse">
      <vs-button @click="createReport">Confirmar</vs-button>
    </div>
  </div>
</template>

<script>
import SelectTypeSchedule from '@/components/schedule/SelectTypeSchedule'
import SelectStatusSchedule from '@/components/schedule/SelectStatusSchedule'
import PopupPdf from '@/components/report/pdf/PopupPdf.vue'

export default {
  components: {
    SelectTypeSchedule,
    SelectStatusSchedule,
    PopupPdf
  },
  props: {
    value: {},
    format: {}
  },
  data () {
    return {
      type: '',
      status: []
    }
  },
  methods: {
    createReport () {
      this.$refs.popupPdf.post({
        url:  `${process.env.VUE_APP_API_URL}/report/exam/practical/passRate`,
        payload: {
          type: this.type,
          format: this.format,
          status: this.status,
          name: 'Índice de aprovação de exame',
          ...this.value
        }
      })
    }
  }
}
</script>

<style>

</style>
