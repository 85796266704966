var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "student-documents" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup80 noOverflow",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupShowPdf,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowPdf = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup60",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupFinancial,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupFinancial = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Serviços Vendidos"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    options: _vm.servicePackages,
                    multiple: "",
                    placeholder: "TODOS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.financial.servicePackages,
                    callback: function ($$v) {
                      _vm.$set(_vm.financial, "servicePackages", $$v)
                    },
                    expression: "financial.servicePackages",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupFinancial = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      on: {
                        click: function ($event) {
                          return _vm.generateReport(2)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupContract,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupContract = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-3/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Serviços Contratados"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    options: _vm.servicePackages,
                    multiple: "",
                    placeholder: "TODOS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.servicePackages,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "servicePackages", $$v)
                    },
                    expression: "contract.servicePackages",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Situação"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    reduce: (option) => option.value,
                    options: [
                      { value: "IN_PROGRESS", label: "Em Andamento" },
                      { value: "FINISHED", label: "Finalizado" },
                      { value: "CANCELED", label: "Cancelado" },
                    ],
                    placeholder: "TODAS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.situation_contract,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "situation_contract", $$v)
                    },
                    expression: "contract.situation_contract",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupContract = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      on: {
                        click: function ($event) {
                          return _vm.generateReport(5)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: _vm.service.title,
            active: _vm.popupService,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupService = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Serviços"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    options: _vm.servicePackages,
                    multiple: "",
                    placeholder: "TODOS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.service.servicePackages,
                    callback: function ($$v) {
                      _vm.$set(_vm.service, "servicePackages", $$v)
                    },
                    expression: "service.servicePackages",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupService = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      on: {
                        click: function ($event) {
                          return _vm.generateReport(1)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: _vm.service.title,
            active: _vm.popupRenach,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupRenach = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Serviços"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    options: _vm.servicePackages,
                    multiple: "",
                    placeholder: "TODOS",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.renach.servicePackages,
                    callback: function ($$v) {
                      _vm.$set(_vm.renach, "servicePackages", $$v)
                    },
                    expression: "renach.servicePackages",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupRenach = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      on: {
                        click: function ($event) {
                          return _vm.generateReport(3)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "vx-card p-6" }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap items-center" },
          [
            _c("div", { staticClass: "flex-grow" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "date", label: "Data Inicial" },
                      on: { change: _vm.checkPeriod },
                      model: {
                        value: _vm.firstPeriod,
                        callback: function ($$v) {
                          _vm.firstPeriod = $$v
                        },
                        expression: "firstPeriod",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "date", label: "Data Final" },
                      on: { change: _vm.checkPeriod },
                      model: {
                        value: _vm.lastPeriod,
                        callback: function ($$v) {
                          _vm.lastPeriod = $$v
                        },
                        expression: "lastPeriod",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.periodError,
                      expression: "periodError",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.periodErrorMessage))]
              ),
            ]),
            _c("vs-input", {
              staticClass:
                "sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-0 ml-4",
              class: { "mb-6": _vm.periodError },
              attrs: {
                label: "Nome do Relatório",
                placeholder: "Busca rápida ...",
              },
              on: { input: _vm.updateSearchQuery },
              model: {
                value: _vm.searchQuery,
                callback: function ($$v) {
                  _vm.searchQuery = $$v
                },
                expression: "searchQuery",
              },
            }),
          ],
          1
        ),
        _c("h3", [_vm._v("Relatórios")]),
        _c("div", { staticClass: "vx-row mt-4" }, [
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full mt-0" },
            [
              _c(
                "vs-table",
                {
                  staticClass: "bordered",
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: {
                    noDataText: "",
                    data: _vm.preDocumentsFiltered,
                    hoverFlat: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _vm._l(data, function (doc, i) {
                          return _c(
                            "vs-tr",
                            {
                              key: i,
                              staticStyle: {
                                "font-size": "12px",
                                opacity: "1 !important",
                              },
                            },
                            [
                              _c(
                                "vs-td",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "cursor-default font-semibold",
                                    },
                                    [_vm._v(_vm._s(doc.title))]
                                  ),
                                ]
                              ),
                              _c("vs-td", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex flex-wrap items-center",
                                  },
                                  [
                                    _c(
                                      "vx-tooltip",
                                      {
                                        staticClass: "mt-2",
                                        attrs: {
                                          text: "Relatório PDF",
                                          position: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "svg-hover h-5 w-5 cursor-pointer",
                                            staticStyle: {
                                              "-ms-transform": "rotate(360deg)",
                                              "-webkit-transform":
                                                "rotate(360deg)",
                                              transform: "rotate(360deg)",
                                            },
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              "xmlns:xlink":
                                                "http://www.w3.org/1999/xlink",
                                              "aria-hidden": "true",
                                              focusable: "false",
                                              width: "0.86em",
                                              height: "1em",
                                              preserveAspectRatio:
                                                "xMidYMid meet",
                                              viewBox: "0 0 1536 1792",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.print(doc, "PDF")
                                              },
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vx-tooltip",
                                      {
                                        staticClass: "mt-2 ml-2",
                                        attrs: {
                                          text: "Relatório Excel",
                                          position: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "svg-hover h-5 w-5 cursor-pointer",
                                            staticStyle: {
                                              "-ms-transform": "rotate(360deg)",
                                              "-webkit-transform":
                                                "rotate(360deg)",
                                              transform: "rotate(360deg)",
                                            },
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              "xmlns:xlink":
                                                "http://www.w3.org/1999/xlink",
                                              "aria-hidden": "true",
                                              focusable: "false",
                                              width: "1em",
                                              height: "1em",
                                              preserveAspectRatio:
                                                "xMidYMid meet",
                                              viewBox: "0 0 24 24",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.print(doc, "EXCEL")
                                              },
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("TÍTULO")]),
                      ]),
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("GERAR")]),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full mt-0" },
            [
              _c(
                "vs-table",
                {
                  staticClass: "bordered",
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: {
                    noDataText: "",
                    data: _vm.preDocumentsFilteredCol2,
                    hoverFlat: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _vm._l(data, function (doc, i) {
                          return _c(
                            "vs-tr",
                            {
                              key: i,
                              staticStyle: {
                                "font-size": "12px",
                                opacity: "1 !important",
                              },
                            },
                            [
                              _c(
                                "vs-td",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "cursor-default font-semibold",
                                    },
                                    [_vm._v(_vm._s(doc.title))]
                                  ),
                                ]
                              ),
                              _c("vs-td", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex flex-wrap items-center",
                                  },
                                  [
                                    _c(
                                      "vx-tooltip",
                                      {
                                        staticClass: "mt-2",
                                        attrs: {
                                          text: "Relatório PDF",
                                          position: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "svg-hover h-5 w-5 cursor-pointer",
                                            staticStyle: {
                                              "-ms-transform": "rotate(360deg)",
                                              "-webkit-transform":
                                                "rotate(360deg)",
                                              transform: "rotate(360deg)",
                                            },
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              "xmlns:xlink":
                                                "http://www.w3.org/1999/xlink",
                                              "aria-hidden": "true",
                                              focusable: "false",
                                              width: "0.86em",
                                              height: "1em",
                                              preserveAspectRatio:
                                                "xMidYMid meet",
                                              viewBox: "0 0 1536 1792",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.print(doc, "PDF")
                                              },
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vx-tooltip",
                                      {
                                        staticClass: "mt-2 ml-2",
                                        attrs: {
                                          text: "Relatório Excel",
                                          position: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "svg-hover h-5 w-5 cursor-pointer",
                                            staticStyle: {
                                              "-ms-transform": "rotate(360deg)",
                                              "-webkit-transform":
                                                "rotate(360deg)",
                                              transform: "rotate(360deg)",
                                            },
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              "xmlns:xlink":
                                                "http://www.w3.org/1999/xlink",
                                              "aria-hidden": "true",
                                              focusable: "false",
                                              width: "1em",
                                              height: "1em",
                                              preserveAspectRatio:
                                                "xMidYMid meet",
                                              viewBox: "0 0 24 24",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.print(doc, "EXCEL")
                                              },
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("TÍTULO")]),
                      ]),
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("GERAR")]),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }