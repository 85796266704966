<template>
  <div>
    <vs-popup :active.sync="localActive" title="Comissões por data de contratação de contrato">
      <contract-employee-commission v-model="value"  @show="localActive = false"/>
    </vs-popup>
  </div>
</template>

<script>
import ContractEmployeeCommission from './../ContractEmployeeCommission'
export default {
  components: {
    ContractEmployeeCommission
  },
  props: {
    active: {},
    value: {},
    students: {}
  },
  data () {
    return {}
  },
  computed:{
    localActive: {
      get () {
        return this.active
      },
      set (value) {
        this.$emit('update:active', value)
      }
    }
  }
}
</script>

