var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          attrs: {
            active: _vm.localActive,
            title: "Índice de exames com financeiro",
          },
          on: {
            "update:active": function ($event) {
              _vm.localActive = $event
            },
          },
        },
        [
          _c("exam-pass-rate-financial", {
            attrs: { format: _vm.format },
            on: {
              show: function ($event) {
                _vm.localActive = false
              },
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }