<template>
  <div>
    <vs-popup :active.sync="localActive" title="Índice de exames com financeiro">
      <exam-pass-rate-financial v-model="value"  @show="localActive = false" :format="format" />
    </vs-popup>
  </div>
</template>

<script>
import ExamPassRateFinancial from '../ExamPassRateFinancial.vue'
export default {
  components: {
    ExamPassRateFinancial
  },
  props: {
    value: {},
    active: {},
    format: {}
  },
  data () {
    return {
    }
  },
  computed: {
    localActive: {
      get () {
        return this.active
      },
      set (value) {
        this.$emit('update:active', value)
      }
    }
  }
}
</script>

<style>

</style>
