var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c("label", { staticClass: "vs-input--label" }, [_vm._v("Tipo")]),
      _c("v-select", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate.initial",
            value: "required",
            expression: "'required'",
            modifiers: { initial: true },
          },
        ],
        staticClass: "fix-vselect",
        attrs: {
          multiple: _vm.multiple,
          clearable: false,
          filterable: true,
          reduce: (option) => option.value,
          options: _vm.options,
          "data-vv-as": "Tipo",
          appendToBody: _vm.appendToBody,
          placeholder: _vm.placeholder,
          autoscroll: false,
          name: "single",
          dir: _vm.$vs.rtl ? "rtl" : "ltr",
          disabled: _vm.disabled,
        },
        scopedSlots: _vm._u([
          {
            key: "no-options",
            fn: function ({}) {
              return [_vm._v(" Nenhum resultado encontrado. ")]
            },
          },
        ]),
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      }),
      _vm.required
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("single"),
                  expression: "errors.has('single')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(_vm._s(_vm.errors.first("single")))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }