<template>
    <div class="w-full">
      <label class="vs-input--label">Tipo</label>
      <v-select
        v-model="localValue"
        :multiple="multiple"
        :clearable="false"
        :filterable="true"
        :reduce="(option) => option.value"
        :options="options"
        data-vv-as="Tipo"
        :appendToBody="appendToBody"
        :placeholder="placeholder"
        class="fix-vselect"
        :autoscroll="false"
        v-validate.initial="'required'"
        name="single"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        :disabled="disabled"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
      <span v-if="required" class="text-danger text-sm" v-show="errors.has('single')">{{
        errors.first('single')
      }}</span>
    </div>
</template>

<script>
// Store Module

export default {
  props: {
    value: {
      default () {
        return {
          id: null
        }
      }
    },
    placeholder: {},
    required: {},
    multiple: {
      default: false
    },
    only: {
      default: []
    },
    appendToBody: {},
    disabled: {}
  },
  data () {
    return {
      data: [],
      allOptions: [
        {
          value: 'practical',
          label: 'Aula prática'
        }, {
          value: 'commitments',
          label: 'Compromisso'
        }, {
          value: 'psychotechnical',
          label: 'Teste psicotécnico'
        }, {
          value: 'theoretical',
          label: 'Aula teórica'
        }, {
          value: 'medical_exam',
          label: 'Exame médico'
        }, {
          value: 'theoretical_exam',
          label: 'Exame teórico'
        }, {
          value: 'practical_exam',
          label: 'Exame prático'
        }
      ]
    }
  },
  computed: {
    options () {
      if (this.only.length) return this.allOptions.filter(e => this.only.includes(e.value))
      return this.allOptions
    },
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('single')
    }
  }
}
</script>
