<template>
    <div class="w-full">
      <label class="vs-input--label">Situação</label>
      <v-select
        v-model="localValue"
        :multiple="multiple"
        :clearable="false"
        :filterable="true"
        :reduce="(option) => option.value"
        :options="options"
        data-vv-as="Situação"
        :placeholder="placeholder"
        :appendToBody="appendToBody"
        class="fix-vselect"
        :autoscroll="false"
        v-validate.initial="'required'"
        name="single"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        :disabled="disabled"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
      <span v-if="required" class="text-danger text-sm" v-show="errors.has('single')">{{
        errors.first('single')
      }}</span>
    </div>
</template>

<script>
// Store Module

export default {
  props: {
    value: {
      default () {
        return {
          id: null
        }
      }
    },
    multiple: {
      default: false
    },
    placeholder : {},
    appendToBody: {},
    disabled: {},
    required: {}
  },
  data () {
    return {
      data: [],
      examOptions: [
        {
          value: 'Aguardando Teste',
          label: 'Aguardando Teste'
        },
        {
          value: 'Inapto',
          label: 'Inapto'
        },
        {
          value: 'Apto',
          label: 'Apto'
        },
        {
          value: 'ABSENCE',
          label: 'Ausente'
        },
        {
          value: 'Aguardando Testes',
          label: 'Aguardando Testes'
        },
        {
          value: 'Pendente',
          label: 'Pendente'
        },
        {
          value: 'Concluído',
          label: ''
        }
      ]
    }
  },
  computed: {
    options () {
      return this.examOptions
    },
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('single')
    }
  }
}
</script>
